import Loading from "element-ui/packages/loading/src/index.js";

export const showLoading = (config = {}) => {
  return Loading(Object.assign({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0.7)',
  }, config));
};
export const execWithLoading = async (func:() => Promise<any>, timeout:number = 60000, config = {}) => {
  const loading = showLoading(config);
  setTimeout(() => loading.close(), timeout);

  return func().finally(() => loading.close());
};
export default execWithLoading;
