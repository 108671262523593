export default {
  validations: {
    "文字": "characters",
    "数値を入力してください": "Please input a number",
    "整数を入力してください": "Please input an integer",
    "正の整数を入力してください": "Please input a positive integer",
    "日付を入力してください": "Please input a date",
    "日付形式を入力してください": "Please input a date in the format",
    "値を入力してください": "Please input a value",
    "この項目は必須です": "This field is required",
    tel: "Please input a phone number (example: 01-2345-6789)",
    min: "Please input no more than {0}",
    max: "Please input no less than {0}",
    between: "Input value between {min} and {max}.",
    decimal: {
      int: "Integer part must be {0} digits or less",
      decimal: "Decimal part must be {0} digits or less",
      minDecimal: "Decimal part must be {0} digits or more"
    },
    prohibitedChars: "{0} is not allowed",
  },
  auth: {
    signup: {
      agreement: "Agree with {0} and {1} by clicking below",
    }
  },
  day: "day",
  days: "days",
  spec: {
    request: {
      confirm: {
        detail: "Please select the account to submit. Click {0} to see the detail.",
      }
    },
    specs: {
      index: {
        search_placeholder: 'Search by product name, contact person, tag, ingredient name, additive, allergen, origin, related company name, etc.',
        number_of_shared_companies: "Shared To",
        annotation_for_filtered_by_dept: "Currently, only the data of the department to which you belong are displayed.",
        annotation_for_filtered_by_dept_link: "Click here for more details."
      },
      detail: {
        level: "Level {n}",
        contamination_same_factory: "In the same factory, '{allergens}' is produced.",
        contamination_same_line: "In the same manufacturing line, '{allergens}' is produced."
      },
      edit: {
        guarantee_text: `本規格書は、食品衛生法などの関係法令を遵守しており、記載事項を保証するものとします。<br/><br/>本規格書に関して、関連法令などに変更が生じる場合、速やかに通知を行い、再提出の必要がある場合は、これに応じるものとします。`,
      }
    },
    self_submission: {
      detail: {
        alert: "This spec was added manually.<br><br>" +
          "Transcription errors and updates from collection and submission sites are not reflected in real time, which may lead to food accidents.<br><br>" +
          "In order to reflect real-time, you must have the submitter install the 'Smart Food Spec' (free of charge) and collect the spec sheet from \"Collection Management\" at the top of the screen > \"+ New Collection Spec Sheet\" in the upper right corner of the screen."
      }
    },
    receives: {
      detail: {
        display_history: "Submitted at {date}",
        history: "Submitted at {date}",
      },
    },
    forwarding: {
      seller_replacement: "この時、「販売者」のみ「あなたの組織の情報」に差し替えられます。あなたの組織の最新情報は、{0}から確認することが可能です。",
    },
    common: {
      left_days: "within {days} days",
      over_days: "over {days} days",
      required_approvals: "Approval of the remaining {restRequiredApprovalUsersLength} people is required to {typeLabel} this spec.<br><br>" +
        "The required approver names are {userNames}.<br><br>" +
        "Approval will also be reset if the content of the spec is changed.",
      per: "Per {n}",
      action_by: "{action} by {by}",
      management_tag: "Mng.",
      displaying: "Displaying {item}",
      export_alert: '<ul>' +
        '<li>This may take several tens of seconds</li>' +
        '<li>Currently, we have confirmed that an error occurs when some additives, etc. are set. This will be addressed in an update.</li>' +
        '</ul>',
    },
  },
  mypage: {
    company: {
      setting: {
        acceptedSpecReconfirmationNotification: "After{1}days since{0}",
      },
      department: {
        delete_alert: `Do you really want to delete this department?<br><br>` +
          `If you delete it, the data belonging to this department may not be visible to anyone other than the managing member.`,
        delete_confirm: 'Cannot delete because a member belongs to this department.<br><br>' +
          'To delete this department, please go to Organization Account Settings > "Add/Manage Members" and remove all members from this department.',
      },
      member: {
        edit: 'Edit {0}',
        delete: 'Delete {0}',
        invitation_sent: 'Invitation email sent.<br>' +
          'The receiver can join the organization by clicking on the link in the email you sent.<br>' +
          'If not receive it, please check your "Spam Mail Folder" or other such folders.',
      },
      invoice: {
        cancel_label: `If you cancel your subscription, you will not be able to view the registered "raw materials" and "products".<br><br>` +
          `You can view it again when you re-subscribe.<br><br>You can continue to use the service until "{0}" after the cancellation.`,
        cancel_spec: `If you cancel your subscription, you will not be able to view the registered "Specifications", "Collections", and "Submissions".<br><br>` +
          `You can view it again when you re-subscribe.<br><br>You can continue to use the service until "{0}" after the cancellation.`,
        canceled: `This will plan stop at "{0}"<br><br>If you press "Re-subscribe" from the bottom, you can continue to use the service as before.`,
        resume_label: `Again, sign up for "Smart Food Label".<br><br>Registered "Ingredients" and "Products" can be viewed, edited, and newly registered.`,
        resume_spec: `Again, sign up for "Smart Food Spec".<br><br>Registered "Owned Specifications", "Collections" and "Submissions" can be viewed, edited, and newly registered.`,
        can_use_until: "可使用至{0}",
      }
    }
  },
  common: {
    of: "{action} of {item}",
    select: "Select {item}",
    name: "{item} Name",
    gte: "{0] or greater",
    lte: "{0] or less",
    search_result: `Result for "{text}" is {total} counts ({count} results displayed)`,
    required: "*",
    optional: "",
    append: " {0}",
    week: "{n} week | {n} weeks",
    month: "{n} month | {n} months",
  },
  utils: {
    only_japanese: '',
  },
  "スマート食品表示": "Smart Food Label",
  "スマート食品規格書": "Smart Food Spec",
  "食品規格書": "Specification",
  "食品表示": "Food Label",
  "商品": "Product",
  "原材料": "Ingredients",
  "製品": "Product",
  "規格書": "Spec",
  "回収": "Collection",
  "提出": "Submission",

  "機能": "Features",
  "価格": "Price",
  "お問い合わせ": "Contact",
  "ログイン": "Login",
  "無料で始める": "Get Started for Free",
  "規格書を無料作成": "Create and Collect",
  "カンタン回収": "your Spec for Free",
  "面倒な規格書の回収作業、更新をカンタンに管理。": "Manage complicated collection",
  "作成側も無料作成、取引先への通知もカンタンに。": "and updating of spec documents easily.",
  "規格書を無料で作成": "Create specifications for free.",
  "見やすく、分かりやすい操作で": "Easy-to-read, easy-to-understand operation",
  "標準商品規格書（SSSP/2014）に対応": "Compatible with SSSP/2014 spec product specifications",
  "取引先の導入で、紙のやりとりも不要に": "Introducing to your business partners, no more paperwork",
  "オプションで原材料名・栄養計算を自動で": "Optional automatic list of ingredients  ad nutrition calculation",
  "規格書の回収もラクラク": "Effortless collection of specifications",
  "紙や電話は不要！必要な情報を簡単に": "No more paper, no more phones! Get essential information easily!",
  "紙や電話のやりとりが不要に": "No more paper or phone calls",
  "不足情報もカンタン収集": "Easy access to missing information",
  "更新があればすぐに把握": "Receive updates instantly",
  "すごいスピードで": "Number of users",
  "導入が増えています！": "are increasing rapidly!",
  "“取引先に規格書の提出を求められたのですが、簡単に作れるだけでなく、オプションで食品表示・栄養成分も自動計算することができ、スムーズに提出できました。”":
    "We needed to submit a specifications document to our business partner, and not only was it easy to create, but with the optional automatic calculation of food labels and nutritional information, we could submit it smoothly.",
  "HAさん / 41歳 / 福岡 / 食品メーカー": "HA / 41 / Fukuoka / Food manufacturer",
  "“このシステムを導入してから、規格書のやりとりにかける手間が大幅に削減されました。”":
    "Since the introduction of this system, the time and effort spent on exchanging specifications has been greatly reduced.",
  "SYさん / 61歳 / 東京 / 食品卸": "SY / 61 / Tokyo / Food wholesaler",
  "“規格書が更新されるたびに紙を受け取っていたのですが、これは自動で送られてくるので、非常に助かります。”": "I used to receive a paper copy of the spec every time it was updated, but this is very helpful because it is sent automatically.",
  "AOさん / 45歳 / 東京 / 食品メーカー": "AO / 45 / Tokyo / Food manufacturer",
  "コンプライアンスを意識する": "Compliance-conscious companies ",
  "事業者様の間で導入が進んでいます": "are adopting the system increasingly.",
  "コンプライアンスを": "Compliance-conscious",
  "意識する事業者様の間で": "companies are adopting",
  "導入が進んでいます": "the system increasingly.",
  "動作環境": "Operating environment",
  "Windows・Macで使えます。": "Can be used on Windows and Mac.",
  "複雑な設定は、必要ありません。「今スグ」使えます。": "No complicated settings are required. You can use it right away.",
  "動作保証": "Operation guarantee",
  "Windows Edge": "Google Chrome",
  "動作保証外": "Operation not guaranteed",
  "Mozilla Firefox": "Mozilla Firefox",
  "Safari": "Safari",
  "Internet Explorer": "Internet Explorer",
  "それぞれ最新である必要があります。": "You need to have the latest version of each browser.",
  "「Google Chrome」は、世界でもっとも使われているブラウザです。": "Google Chrome is the most popular browser in the world.",
  "現在は保証外をお使いでも、コチラから3分程度で動作保証の導入が可能です。": "Even if you are currently using an out-of-support browser, you can install it in about three minutes here.",
  "登録する「商品・原材料」の数によって自動で最適な価格になります。": "The price is automatically optimized according to the number of 'products and ingredients' you register.",
  "他社サービスより「リーズナブル」な導入を目指します。": "We aim to introduce a more 'affordable' price than other companies' services.",
  "回収管理": "Collected Specs",
  "規格書作成": "Create Spec",
  "アカウント": "Account",
  "フリー": "Free",
  "規格書の無料作成と、回収を始めたい方": "For those who want to create a specifications for free and start collection.",
  "無料": "Free",
  "さっそく始める": "Start Now",
  "15 枚まで": "Up to 15 sheets",
  "無制限": "No limit",
  "※上限あり": "There is a limit",
  "スターター": "Starter",
  "規格書の無料回収、規格書回収量が少量の方": "Free collection of specifications, for those who collect a small amount of specifications",
  "2,480円/月": "2,480 yen/month",
  "気軽に相談": "Contact",
  "75 枚まで": "Up to 75 sheets",
  "スタンダード": "Standard",
  "規格書の無料回収、規格書回収が中規模の方": "Free collection of specifications, medium-sized collection of specifications",
  "4,980円/月": "4,980 yen/month",
  "150 枚まで": "Up to 150 sheets",
  "プレミアム": "Premium",
  "規格書の無料回収、規格書回収量が大量の方": "Free collection of specifications, large volume collection of specifications",
  "9,800円/月": "9,800 yen/month",
  "300 枚まで": "Up to 300 sheets",
  "エンタープライズ": "Enterprise",
  "規格書の回収が非常に多くある大企業向け": "For large companies that collect a large number of specifications.",
  "要相談": "Please contact us.",
  "共通機能": "Common functions",
  "規格書の作成": "Create Spec",
  "標準商品規格書（SSSP/2014）対応": "Support for spec product specifications (SSSP/2014)",
  "オプション）原材料名・栄養の自動計算": "(Optional) Automatic calculation of raw material names and nutrition",
  "規格書の提出管理": "Submission of Specs",
  "企業ごとの規格書提出": "Submission of specifications for each company",
  "規格書の更新通知": "Update Notification of Specifications.",
  "規格書の回収管理": "Collect Specs",
  "規格書ごとの回収管理": "Collection management for each specs",
  "不足情報の連絡": "Communication of Shortage Information",
  "その他のオプション": "Other Options",
  "「他システムと連携して欲しい。」「食品表示の計算が面倒」": "Please feel free to contact us questions such as",
  "そんなご要望にお応えします。お気軽にご相談ください。": "'linking with other systems' and 'complicated calculation of food labeling' and so on.",
  "規格書に加えて『スマート食品表示』も契約することで、カンタンに原材料名・栄養成分の自動計算ができます。": "By subscribing to 'Smart Food Label' in addition to the specifications, you can easily and automatically calculate the names of ingredients and nutritional ingredients.",
  "規格書をやりとりすると、後はレシピ入力だけで原材料名・栄養成分の計算が可能です。": "After exchanging the specifications, you can calculate the names of ingredients and nutritional information simply by entering recipes.",
  "『スマート食品表示』へ": "To 'Smart Food Label'.",
  "入力代行": "Input Service",
  "詳細はこちら": "For more information",
  "やっていたくだことは、書類を揃えていただくだけ！": "All you need to do is to prepare the documents",
  "あなたの代わりにデータを作成します。": "and we will prepare the data for you.",
  "ご希望の書類の作成": "Preparation of documents of your choice",
  "ご利用中の「基幹」システムとの連携": "Linking with the \'core\' system in your company",
  "ご利用中の「他社」システムとの連携": "Linking with \'other company's\' systems",
  "その他、お気軽にご相談ください。": "Please feel free to contact us for more information.",
  "気軽に相談する": "Feel free to contact us.",
  "さぁ！これで手間が": "No more",
  "かかる紙の規格書とは": "Time-consuming paper",
  "おさらば！": "Specifications!",
  "さっそく試す": "Try it now!",
  "サービスを知る": "Learn more about the service",
  "利用者の声": "User's Voice",
  "もっと知る": "Learn More",
  "よくある質問": "FAQ",
  "利用規約など": "Terms of Use",
  "利用規約": "Terms of Use",
  "プライバシーポリシー": "Privacy Policy",
  "特定商取引法に基づく表記": "Notation based on the Act on Specified Commercial Transactions",
  "関連サービス": "Related Services",
  "スマート食品表示代行": "Smart Food Label Agency",
  "©2021 スマート食品表示": "©2021 Smart Food Label",
  "たった5分で新規作成": "Signup within 5 minutes",
  "無料で始めることができます。": "Get started for free.",
  "既に同じ組織の方がアカウントを持っている場合、下記は入力せず、管理メンバーから招待メールを受け取ってください": "If someone from the same organization already has an account, please do not enter the following and receive an email invitation from the managing member.",
  "メールアドレス": "E-mail address",
  "必須": "Required",
  "普段お使いのメールアドレス": "Your regular email address",
  "この項目は必須です": "This section is required",
  "利用規約 と プライバシーポリシー に同意の上、以下のボタンから作成してください。": "Please agree to the Terms of Use and Privacy Policy and click the button below to create your account.",
  "以上で作成": "Create",
  "アカウントを既にお持ちの方はコチラ": "Already have an account?",
  "メールを送信しました": "Email sent.",
  "本人確認のためにメールを送信しました。届いたメールに書かれているアドレスを開いてください。": "An email has been sent to you to confirm your identity. Please open the address in the email you received.",
  "本人確認完了": "Identity verification complete",
  "メールアドレスを確認いたしました。パスワードおよび所属する組織とあなたの情報を入力してください。": "We have verified your email address. Please enter your password and your organization and your information.",
  "パスワード": "Password",
  "8文字以上の英数字": "At least 8 alphabetical characters",
  "組織名（屋号・法人名など）": "Organization name (trade name, corporation name, etc.)",
  "株式会社スマート": "Smart Co.",
  "ご連絡先（電話番号）": "Contact (phone number)",
  "例）01-2345-6789": "e.g. 01-2345-6789",
  "あなたの名前": "Your Name",
  "お名前": "Your Name",
  "例）須磨都 太郎": "e.g. Taro Sumato",
  "須磨都 太郎": "Taro Sumato",
  "次へ": "Next.",
  "登録が完了しました！": "Your registration is complete!",
  "さっそく管理画面に移動して、あなたの商品を管理しましょう。": "Let's get started and go to the administration page to manage your products.",
  "さっそく使う": "Start Now",
  "以下の情報を入力して、ログインしてください。": "Please enter your information below to login.",
  "パスワードをお忘れの方はこちら": "Forgot your password?",
  "アカウントをお持ちでない方はコチラ": "Don't have an account?",
  "パスワード再設定メールの送信": "Reset Password",
  "ご登録いただいたメールアドレスにパスワード再設定用のアドレスをお送りします。": "We will send you a password reset address to your registered email address.",
  "ご登録時のメールアドレス": "registered email address",
  "メールアドレスを入力してください": "Please enter your email address",
  "ログイン画面へ戻る": "Back to Login Page",
  "確認メールが送信されました": "Confirmation email sent",
  "パスワード再設定メールが送信されました": "Password reset email sent",
  "送信": "Submit",
  "パスワードの再設定": "Password reset",
  "メールに記載されているアドレスから、パスワードを再設定してください。": "Please reset your password from the address in the email.",
  "パスワード再設定": "Password reset",
  "下記を入力して、パスワードを再設定してください。": "Please enter the following to reset your password",
  "新しいパスワード": "New Password",
  "新しいパスワード（確認）": "New password (confirmation)",
  "パスワードの再設定が完了しました！": "Your password has been reset!",
  "新しく設定したパスワードでログインしてください。": "Please log in with your newly set password.",
  "自社規格書": "Owned Specs",
  "提出管理": "Submissions",
  "としてログイン": "Log in as",
  "表示言語の変更": "Change display language",
  "表示言語の選択": "Select display language",
  "システムの表示言語を変更することができます。": "You can change the display language of the system.",
  "言語の選択": "Language Selection",
  "言語を変更しました。画面を再読み込みしてください": "Language changed. Please reload the screen.",
  "閉じる": "Close",
  "以上で変更": "Change",
  "個人アカウント設定": "Personal Account Settings",
  "ログアウト": "logout",
  "所属している組織": "Organization to which you belong",
  "組織ID": "organization ID",
  "取引先から提出依頼を受ける際にこちらのIDを指定する必要があります": "You need to specify this ID when you receive a submission request from your business partner.",
  "組織アカウント設定": "Organization Account Settings",
  "請求情報": "Billing Information",
  "プロフィール": "Profile",
  "個人プロフィール": "Personal Profile",
  "こちらから、名前等を変更することができます。": "You can change your name and other information here.",
  "名前": "Name",
  "名前（フリガナ）": "Name (furigana)",
  "フリガナ": "Furigana",
  "例）スマト タロウ": "e.g. Taro Yamada",
  "生年月日": "Date of birth",
  "任意": "Optional",
  "年 / 月 / 日": "Year / Month / Day",
  "変更を保存": "Save Changes",
  "変更したいメールアドレスを入力し、下記ボタンを押してください。届いたメールから承認を行うと、登録されているメールアドレスが変更されます。": "Please enter the email address you wish to change and click the button below. Once you approve from the email you received, your registered email address will be changed.",
  "変更メールを送信": "Send a Confirmation Email.",
  "こちらから、パスワードを変更することができます。": "You can change your password here.",
  "現在のパスワード": "Current Password",
  "組織プロフィール": "Company Profile",
  "この画面は、管理メンバーのみに表示されています。組織の名前や電話番号などを変更することができます。「食品表示」や「規格書」などに自動で挿入されるため、正確な情報を入力ください。またすでに送信した規格書には、変更は反映されません。": "This screen is visible only to administrative members. You can change the name and phone number of your organization. Please make sure to enter accurate information as it will be automatically inserted into the 'Food Label' and 'Specifications' sections. Also, changes will not be reflected in specs already submitted.",
  "ID": "ID",
  "組織名": "Organization Name",
  "例）株式会社スマート": "e.g. Smart Co.",
  "組織名（カタカナ）": "Organization name (in katakana)",
  "例）カブシキガイシャスマート": "e.g. Kabushiki Kaisha Smart",
  "組織のメールアドレス": "Organization's email address",
  "例）sample＠mail.com": "e.g. sample@mail.com",
  "組織の電話番号": "Phone Number",
  "組織のFAX番号": "FAX number",
  "組織のホームページURL": "Organization's website URL",
  "例）https://xxxx.xx.xx": "e.g. https://xxxx.xx.xx",
  "所在地": "Location",
  "例）日本": "e.g. Japan",
  "例）000-0000": "e.g. 000-0000",
  "例）都道府県": "e.g. Prefecture",
  "例）市区町村番地": "e.g.) City/town/village code",
  "例）建物": "e.g. Building",
  "PL保険加入": "PL Insurance",
  "規格書設定": "Spec Setting",
  "この画面で設定をすると、組織内の全員に同じ設定が適用されます。": "When settings are made in this screen, the same settings will be applied to all members in the organization.",
  "連携（スマート食品表示）": "Connect (Smart Food Label)",
  "スマート食品規格書で回収・作成した原材料を自動でスマート食品表示の原材料に追加する。": "Automatically add ingredients collected and created in 'Smart Food Spec' to ingredients in Smart Food Label",
  "スマート食品表示から該当商品の栄養成分を引用する際、「Tr」（極微量）は「0」（ゼロ）として処理する。": "When quoting the nutritional content of the relevant product from the smart food label, 'Tr' (very small amount) is processed as '0' (zero).",
  "社内承認と稟議": "Internal approvals",
  "「自社規格書」に必要な「承認者」の選択": "Selection of 'Approver' required for 'Owned Specs",
  "既存メンバーから選択": "Select from existing members",
  "「回収規格書」に必要な「承認者」の選択": "Select the 'Approver' required for the 'Collection Spec.'",
  "組織全体の設定": "Organization-wide setting",
  "部署管理": "Department Management",
  "部署の設定": "Department setting",
  "部署間のデータの閲覧・編集権限": "Permission between departments",
  "添加物を入力する際の「E番号」表示": "Show E No. when entering additives",
  "回収・提出先": "Clients & Suppliers",
  "こちらから、自社が規格書の回収または提出している企業の情報を閲覧・編集することができます。": "Here you can view and edit information on companies whose specs are collected or submitted by your company.",
  "追加する部署名を入力して選択してください": "Please select the department name to be added",
  "追加する部署名を入力して選択してください。": "Please select the department name to be added.",
  "この操作をする権限がありません": "You do not have permission to perform this operation",
  "未分類": "Uncategorized",
  "企業名": "Company Name",
  "都道府県": "State/Province",
  "TEL": "TEL",
  "FAX": "FAX",
  "PL加入": "PL Insurance",
  "データなし": "No data",
  "販売者": "Seller",
  "製造者": "Manufacturer",
  "加工者": "Processors",
  "輸入者": "Importer",
  "提出元（回収手入力用）": "Submission Source (for manual entry of collection)",
  "関連事業者": "Partners",
  "こちらから、この組織が取引している「製造者」「加工者」「輸入者」などを管理することができます。登録した情報は「食品表示」や「規格書」を入力する際に、入力補助として使用することができます。": "From here, you can manage the 'manufacturers', 'processors', 'importers', etc. with whom this organization does business. The registered information can be used as an input aid when entering 'food labels' and 'specifications.",
  "製造所": "Manufactory",
  "加工所": "Processing plant",
  "メンバー追加・管理": "Members",
  "この画面は、管理メンバーのみに表示されています。こちらから、この組織に所属している方を追加・編集・削除することができます": "This screen is visible only to administrative members. You can add, edit, and delete people who belong to this organization from here",
  "メンバー一覧": "Member List",
  "＋メンバーの追加": "Add a Member",
  "新しいメンバーを追加": "Add new member",
  "以下を入力して「招待」を押してください。": "Please enter the following and press 'Invite'.",
  "例）test@mail.com": "e.g. test@mail.com",
  "権限": "Authority",
  "キャンセル": "Cancel",
  "以上で招待": "Invite",
  "への参加": "Participation in",
  "下記を入力して、組織に参加してください。": "Please enter the following to join our organization",
  "さっそく管理画面に移動しましょう。": "Let's quickly go to the administration page.",
  "管理画面に移動": "Go to Administration",
  "メンバー名": "Member Name",
  "登録日": "Registration Date",
  "の権限・部署を変更する": "Change the authority and department of",
  "をメンバーから削除": "removed from membership",
  "招待済のメンバー一覧": "Taro Sumato",
  "招待日": "day or date of an invitation",
  "請求": "Invoice",
  "この画面は、管理者のみに表示されています。こちらでは、現在ご契約のプランの確認や、過去の利用詳細を確認することができます。": "This page is only visible to the administrator. Here, you can check your current plan and view your previous usage details.",
  "スタータープラン": "Starter Plan",
  "スタンダードプラン": "Standard Plan",
  "プレミアムプラン": "Premium Plan",
  "メンドウな法律の原材料名や、": "Easy to calc the",
  "栄養成分の計算をカンタンに！": "ingredients recipes!",
  "もう一つのサービス。無料期間あります。": "Another service. There is a free period.",
  "もう一つのサービス。今なら無料プランあります。": "Another service. There is a free plan now.",
  "詳細はコチラ": "Click here for details",
  "次回請求:": "Next bill:",
  "解約": "Cancel Plan",
  "フリープラン": "Free Plan",
  "0円": "0 yen",
  "お支払い方法": "Payment Method",
  "(登録されていません)": "(Not registered)",
  "カード切替": "Switch card",
  "請求日": "Billing Date",
  "サービス": "Service",
  "種類": "Type",
  "請求額": "Billing Amount",
  "領収書": "Receipt",
  "お支払い": "Payment",
  "※ 利用料金は各契約期間の初日に請求され、お客様のアカウントに反映されるまで数日かかることもございます。": "Fees are charged on the first day of each subscription period and may take a few days to reflect in your account.",
  "商品名・担当者・タグ・原材料名・添加物・アレルゲン・原産地・関連企業名などで検索": "Search by product name, staff, tag, ingredient name, additive, allergen, origin, related company name, etc.",
  "出力": "Export",
  "手動追加": "Add Manually",
  "回収規格書の手入力（作成）": "Manual input of collection spec (creation)",
  "回収規格書の手入力": "Manual input of collection spec",
  "以下で保存": "Save",
  "回収規格書（手入力）の保存": "Save collection spec (manual entry)",
  "下記を入力して保存してください。": "Please enter and save the following",
  "提出元の組織": "Submission organization",
  "提出元の組織の選択": "Select the Submission organization",
  "提出元の組織名": "Name of submission organization",
  "提出元の組織名（全角カタカナ）": "Name of submission organization(in katakana)",
  "新規": "New",
  "例）株式会社スマート食品": "e.g. Smart Foods Co.",
  "例）カブシキガイシャスマートショクヒン": "e.g. Kabushiki Kaisha Smart Shokuhin",
  "例）000−0000": "e.g. 000-0000",
  "市町村番地": "City",
  "建物": "Building",
  "電話番号": "Phone Number",
  "例）03-1234-5678": "e.g. 03-1234-5678",
  "FAX番号": "FAX Number",
  "公式サイトURL": "Web Page URL",
  "例）https://smashoku.com": "e.g. https://smashoku.com",
  "自社管理コード": "Internal Code",
  "例）1": "e.g. 1",
  "例）1001": "e.g. 1001",
  "仕入れ税抜単価（円）": "Unit price excluding purchase tax (yen)",
  "例）1300": "e.g. 1300",
  "自社の担当者": "Company Staff",
  "戻る": "Return",
  "以上で保存": "Save",
  "規格書の新規回収依頼": "Request for new collection",
  "規格書の新規回収": "New collection of specifications",
  "以下で依頼": "Request under",
  "規格書の提出依頼": "Request for submission of specifications",
  "規格書の提出依頼を送信します。よろしいですか？（すぐに事業者に送信されます）": "I am submitting a request for the submission of specs. Are you sure? (Will be sent to the business immediately)",
  "依頼先": "Client",
  "依頼先（不明な場合は右記？マークを確認）": "Target Organization (check the right ? mark)",
  "依頼先の組織ID": "Target Organization ID",
  "新しい組織に依頼": "New Organization",
  "履歴": "History",
  "「メール」で新しい組織に依頼": "Request a new organization by \"email\"",
  "「組織ID」で新しい組織に依頼": "Request a new organization by \"Organization ID\"",
  "依頼先のメールアドレス": "Target Organization Email",
  "例）ACXY2345": "e.g. ACXY2345",
  "依頼期限": "Request Deadlines",
  "例）2022/05/12": "e.g. 2022/05/12",
  "依頼時のコメント": "Comments at the time of request",
  "例）お世話になっております。スマート食品の須磨都です。下記商品の規格書作成をお願いいたします。": "e.g. Thank you for your assistance. My name is Sumato of Smart Foods. I would like to request you to prepare a specification for the following product.",
  "回収を依頼したい商品": "Products you wish to request for collection",
  "商品名・商品番号など依頼先に商品内容が分かるもの": "Product name, product number, or other information that identifies the product to the requesting party",
  "例）小麦": "e.g. Wheat",
  "規格書の入力必須事項": "Required Entries for Specifications",
  "依頼先に最低限の情報以外で、必ず入力して欲しい項目を選んでください。": "Please select the fields you would like the requester to enter other than the minimum information.",
  "必須項目を選択（複数選択可）": "Select a required field (multiple selections allowed)",
  "選択してください": "Please Select One",
  "お取引先「{0}」から、下記の規格書の提出依頼が届いています。": "You have the request for following specs from {0}.",
  "依頼があった商品": "Requested Item",
  "希望する提出期限": "Requested Deadlines",
  "ログイン中のアカウントで提出": "Submit by Logged-in User",
  "ログイン中のアカウント": "Logged-in User",
  "上記のアカウントで提出": "Submit",
  "依頼されたメールアドレスは既に登録されています。ログインしてください": "The requested email address is already registered. Please log in.",
  "アカウントを作成して提出": "Create an Account and Submit",
  "既に同じ組織の方がアカウントを持っている場合、下記は入力せず、先に同じ組織の管理者から招待を受けてください。":
    "If someone from the same organization already has an account, please do not enter the following and receive an invitation from the it's administrator.",
  "ログインして提出": "Login and Submit",
  "この提出依頼は、すでにログイン中の組織で受付済みです。": "This request has already been accepted by the logged-in organization.",
  "この提出依頼は、すでに別の組織で受付されています。": "This request has already been accepted by another organization.",
  "依頼された商品を受付しました": "You have accepted the requested item.",
  "ユーザーが作成され、依頼された商品を受付しました。続いて規格書を入力してください。":
    "A user has been created and the requested item has been accepted. Please enter the specifications.",
  "ログインし、依頼された商品を受付しました": "You have logged in and accepted the requested item.",
  "他": "Other",
  "すべて": "All",
  "中断された取引": "Canceled",
  "更新": "Update",
  "商品名": "Product Name",
  "一般名称": "Common Name",
  "タグ": "Tag",
  "希望提出期限": "Requested Due Date",
  "回収先": "Submitter",
  "最終コメント": "Last Comment",
  "自社担当者": "Company Staff",
  "仕入れ金額（税抜）": "Price (tax excluded)",
  "中　断": "interruption",
  "手動": "Manual",
  "更新有": "updateable",
  "提出分を表示中": "Submitted minutes are being displayed.",
  "依頼文・コメント": "Show Comments History",
  "依頼文・コメントの履歴": "History of request letters and comments",
  "依頼時からのコメント履歴を読むことができます。": "You can read the comment history from the time of the request.",
  "コメント履歴": "Comment History",
  "日付": "Date",
  "ステータス": "Status",
  "担当者名": "Staff Name",
  "差戻し（再提出の依頼）": "Request Modification",
  "受領した規格書に納得がいかない場合、「差戻し」（再提出を依頼）をすることができます。":
    "If you are not satisfied with the specifications received, you may request resubmission.",
  "担当者部署": "Staff's department",
  "品質管理部": "Quality Control Dept.",
  "添付コメント": "Attached comments",
  "自由入力": "Free Input",
  "以上で差戻し": "Request modification",
  "問題がないので受領する": "Accept",
  "問題が無いので受領する": "Accept",
  "問題がないことを最終確認して受領": "Accept",
  "以下で受領すると、差戻しはできなくなります。足りない情報がある時は、他の連絡手段により、規格書の発行者に情報を追加してもらってください。":
    "Accepting this you will no longer requiest modification.",
  "以上で受領": "Accepted",
  "MerQurius原料品質規格書（v3.1）フォーマット（Excel）の出力": "MerQurius Raw Material Quality Specification (v3.1) format (Excel) output",
  "規格書を「MerQurius原料品質規格書（v3.1）フォーマット（Excel）」で出力します。出力にあたっては、以下をご確認ください。": "The specifications are output in 'MerQurius Raw Material Quality Specifications (v3.1) Format (Excel)'. Please confirm the following before outputting.",
  "ダウンロードには、数十秒かかることがございます。": "Downloading may take several tens of seconds.",
  "現在一部の添加物等が設定されている場合、エラーが発生することを確認しております。これについては、アップデートにて対応予定です。": "Currently, we have confirmed that an error occurs when some additives, etc. are set. This will be addressed in an update.",
  "このフォーマットで提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。": "If you submit in this format, you will need to manage the submitter each time you update the specifications, so we recommend that you have the submitter implement the 'Smart Food Spec' and then submit them on the system.",
  "出力する食品規格書": "Output Food Specification",
  "MerQurius Net ID": "MerQurius Net ID",
  "例）R12345000001-001": "e.g. R12345000001-001",
  "以上で出力": "Output above",
  "食品規格書の比較": "Comparison of Food Specs",
  "規格書の比較": "Comparison of Specs",
  "この規格書と比較したい、比較先の規格書を選んで下さい。別ウィンドウで比較が表示されます。": "Select the spec you wish to compare to this spec. The comparison will appear in a new window.",
  "比較元の規格書": "Comparison Source Spec",
  "比較先の規格書": "Comparison Target Spec",
  "以上で比較": "Compare with above",
  "管理情報（相手方には表示されません）": "Management information (not displayed to the clients)",
  "自社管理情報": "Internal info",
  "自社管理用メモ": "Memo for internal management",
  "自社管理用メモ（相手には公開されません）": "Memo for internal management (not displayed to the clients)",
  "更新ログ": "Update log",
  "管理情報を変更": "Change internal info",
  "例）1712": "e.g. 1712",
  "選択または自由入力で作成": "Select ot Free Input",
  "選択または自由入力で作成（複数）": "Select or Free Input (multiple)",
  "検索または自由入力で作成": "Search or Free Input",
  "追加項目の有無": "Availability of additional items",
  "追加項目名": "Additional Item Name",
  "項目に対する値": "Value",
  "削除": "Delete",
  "複製": "Duplicate",
  "＋ 規格書の作成": "+ Create Spec",
  "ブランド名": "Brand Name",
  "共有先": "Shared with",
  "最終更新日": "Last Update Date",
  "最終更新者": "Last updated by",
  "を表示中": "displayed",
  "下書き": "Draft",
  "下書き分": "Draft",
  "編集": "Edit",
  "配信": "Publish",
  "管理情報": "Internal",
  "基本情報": "Basic Info",
  "自社規格書タグ": "Owned Spec Tags",
  "担当者": "Staff",
  "選択": "Select",
  "下書き保存": "Save as Draft",
  "保存して配信": "Save and Publish",
  "正規の版として保存": "Save and Publish",
  "正規の版として提出": "Save and Submit",
  "基本": "Basic",
  "企業": "Company",
  "一括表示": "Food Label",
  "栄養": "Nutrition",
  "栄養成分": "Nutrition",
  "栄養成分表示": "Nutrition",
  "包材": "Package",
  "製造": "Manufacture",
  "既存規格書の引用": "Citation of existing spec",
  "引用の有無": "Citation",
  "正式商品名": "Official Product Name",
  "例）スマート印 マヨネーズ": "e.g. Smart Seal Mayonnaise",
  "正式商品名（全角カタカナ）": "Official Product Name (katakana)",
  "例）スマートモリアワセ": "e.g.　Smart Platter",
  "食品区分": "Food Category ",
  "JICFS分類": "JICFS Classification ",
  "メーカー名": "Maker Name",
  "メーカー管理コード": "Maker Code",
  "発売日": "Release Date",
  "例）日付を選択": "e.g. Select a date",
  "適用日": "Applicable date",
  "規格書適用日": "Applicable date",
  "最終受領日": "Last Acceptance Date",
  "日付を選択": "Pick date",
  "商品情報URL": "Product URL",
  "例）https://smashoku.com/product/1": "e.g. https://smashoku.com/product/1",
  "ブランド": "Brand",
  "ブランド区分": "Brand Category",
  "製造日・期限・保存": "Manufacturing date, expiry date, storage",
  "入力方法": "Input method",
  "包材の期限表示例": "Expiry date format on package",
  "包材の期限表示": "Expiry date format on package",
  "包材の製造日表示例": "Manufacturing date format on package",
  "包材の製造日表示": "Display of manufacturing date on package",
  "賞味・消費期限区分": "Expiration category",
  "製造日を含む": "Incl. manufactured date",
  "例）10": "e.g. 10",
  "日": "Date",
  "開封前の保存温度帯": "Storage temperature before opening",
  "開封後の保存温度帯": "Storage temperature after opening",
  "開封前": "Before opening",
  "開封”前”": "Before opening",
  "開封前の": "Before opening ",
  "開封後の": "After opening ",
  "開封前の賞味・消費期限": "Expiration date before opening the package",
  "開封前の期限設定の根拠": "Basis of the expiry date before opening",
  "開封後の期限設定の根拠": "Basis of the expiry date after opening",
  "開封前の賞味期限設定の根拠": "Basis of the expiration date before opening",
  "開封後の賞味期限設定の根拠": "Basis of the expiration date after opening",
  "賞味期限": "Best-before date",
  "消費期限": "Expiry date",
  "品質保証期限": "Quality assurance period",
  "製造年月日": "Manufacturing date",
  "期限設定無し": "No expiry date",
  "例）理化学試験の結果・法令など": "e.g. results of physical and chemical tests, laws and regulations, etc.",
  "出荷前の保存温度帯": "Storage temperature range before shipment",
  "流通時の保存温度帯": "Storage temperature range during distribution",
  "入荷時の保存温度帯": "Storage temperature range at receipt",
  "開封後": "After opening",
  "開封”後”": "After opening",
  "荷姿規格": "Packing Specification",
  "規格入力方法": "Standard Input Method",
  "商品単位": "Product Unit",
  "選択または自由入力": "Select ot Free Input",
  "内容重量": "Content weight",
  "内容重量(g)": "Content weight(g)",
  "例）100": "e.g. 100",
  "総重量(g)": "Gross weight(g)",
  "総重量": "Gross weight",
  "重量": "Weight",
  "例）240g x 20袋 x 2ケース": "e.g. 240g x 20 bags x 2 cases",
  "最低発注単位": "Minimum Order Unit",
  "定貫": "Fixed Qty",
  "不定貫": "Unfixed Qty",
  "内容量（ml）を表記": "Indicated content (ml)",
  "存在": "Existence",
  "単位": "Unit",
  "幅/長辺(mm)": "Width(mm)",
  "高さ(mm)": "Height(mm)",
  "奥/短辺(mm)": "Depth(mm)",
  "幅/長辺": "Width",
  "高さ": "Height",
  "奥/短辺": "Depth",
  "入数": "Qty per package",
  "JANコード": "JAN code",
  "例）12456789": "e.g. 12456789",
  "ITF(GTIN-14)コード": "ITF (GTIN-14) Code",
  "ピース": "Piece",
  "パック": "Pack",
  "ボール": "Balls",
  "ケース": "Case",
  "甲": "Kouri",
  "特徴・召し上がり方・注意点": "Features, Serving Instructions, and Precautions",
  "商品アピール文": "Product Appeal",
  "召し上がり方・使用方法": "How to consume and use",
  "使用・保管・廃棄上の注意": "Precautions for use, Storage, and Disposal",
  "関連する認証": "Related Certifications",
  "認証名": "Certification Name",
  "例）有機JAS": "e.g. JAS Organic",
  "認証・認定機関": "Certification Body",
  "例）一般財団法人スマート食品": "e.g. General Incorporated Foundation Smart Food",
  "認証対象": "Certification Subject",
  "例）加工商品製造": "e.g. Processed Product Manufacturing",
  "認証番号・記号": "Certification number",
  "例）JAS123456789-1234-1": "e.g. JAS123456789-1234-1",
  "備考": "Notes",
  "酒類情報": "Alcoholic Beverage Info",
  "酒類区分": "Alcoholic Beverage Type",
  "酒類分類": "Alcoholic Beverage Category",
  "酒類アルコール度数（%）": "Alcoholic Beverage Alcohol Content (%)",
  "例）5.2": "e.g. 5.2",
  "追加項目": "Additional items",
  "販売者（貴社情報）": "Seller (your company's info)",
  "組織プロフィールから引用": "Quote from Organization Profile",
  "組織名（全角カタカナ）": "Business name (in katakana)",
  "WebページURL": "Web page URL",
  "例)須磨都太郎": "e.g. Taro Suma",
  "例）特になし": "e.g. None",
  "製造者・加工者・輸入者": "Manufacturer/Processor/Importer",
  "区分選択": "Select a category",
  "製造者の選択": "Select Manufacturer",
  "例）ISO22000 取得済": "e.g. ISO22000 certified",
  "上記所在地以外での「製造・加工」": "Settings for manufacturing (factory) and processing facilities",
  "製造所・加工所": "Manufactory (factory) and processing plants",
  "製造所・加工所の選択": "Selection of manufactory (factory) and processing plant",
  "製造所（工場）・加工所": "Manufactory (factory) and processing plants",
  "製造所（工場）、加工所の設定": "Settings of manufactory (factory) and processing plants",
  "スマート食品表示の商品から引用（引用する場合のみ）": "Cite from 'Smart Food Label' products",
  "一覧から、スマート食品表示の商品を選択し、「下記に反映」をクリックしてください。": `Please select the product of "Smart Food Label" in the list and click "Apply"`,
  "下記に反映": "Apply",
  "一つ上に移動": "Up",
  "一つ下に移動": "Down",
  "左に一段階上げる": "Left Up",
  "右に一段階下げる": "Right Down",
  "左に一段落上げる": "Left Up",
  "右に一段落上げる": "Right Down",
  "原材料名・添加物": "Ingredients and additives",
  "例）ホワイトソース": "e.g. White sauce",
  "配合率(％)": "Ratio (%)",
  "配合率": "Ratio",
  "例）20": "e.g. 20",
  "添加物の用途": "Additive Usage",
  "添加物用途": "Additive Usage",
  "表示有無": "Visibility",
  "非表示の理由": "Invisible Reason",
  "未選択": "Not Selected",
  "一括表示上の表示名": "Name on Label",
  "原産地（最終加工地）": "Production Area",
  "製造者名": "Manufacturer Name",
  "仕入元名": "Supplier Name",
  "基原原料": "Base Raw Materials",
  "の基原原料": "Base raw material of",
  "基原原料の産地": "Production area of base raw materials",
  "アレルギー物質": "Allergens",
  "遺伝子組み換え農作物": "GMOs",
  "遺伝子組換え対象農作物": "GMOs",
  "の遺伝子組換え対象農作物": " GMOs",
  "対象農作物": "Target crops",
  "区分": "Category",
  "合計:": "Total :",
  "産地証明書": "Certificate of production area",
  "ここに画像をドラッグ or クリックで選択": "Drag the image or click here to select.",
  "ここにファイルをドラッグ or クリックで選択": "Drag the file or click here to select.",
  "この商品を原材料として使用した場合の表示例（内訳は除く）": "Display name on label when used as an ingredient (excluding inner contents)",
  "この商品を原材料として使用した場合の表示例": "Display name on label when used as an ingredient",
  "例）乳等を主要原料とする食品": "e.g. Food made from milk or other dairy products",
  "配合表全体に対する注意事項": "Notes on the entire ingredients",
  "名称区分・名称": "Name classification and name",
  "名称区分": "Name category",
  "名称": "Meishou(Name)",
  "品名": "Hinmei(Product name)",
  "品目": "Hinmoku(Item name)",
  "種類別": "Shuruibetsu(Category)",
  "種類別名称": "ShuruibetsuMeishou(Category name)",
  "例）サンドイッチ": "e.g. Smart",
  "原材料名": "Ingredient Name",
  "例）トマト、レタス／保存料（ソルビン酸）": "e.g. Tomatoes, lettuce/preservatives",
  "内容量・期限・保存方法": "Content Amount, Expiration Date, Storage Method",
  "内容量区分": "Amount type",
  "内容量": "Content amount",
  "固形量": "Solid volume",
  "内容総量": "Net amount",
  "固形量＋内容総量": "Solid amount + Net amount",
  "法令に基づき非表示": "Not displayed under the law",
  "例）100g": "e.g. 100g",
  "例）200g": "e.g. 200g",
  "例）300g": "e.g. 300g",
  "期限・保存方法": "Expiry date and storage method",
  "賞味・消費期限": "Expiration date",
  "例）枠外右部に記載": "e.g. Listed on the right outside the frame",
  "保存方法": "Storage Method",
  "その他": "Other",
  "その他の項目を選択（複数選択可）": "Select other items (multiple choices are accepted)",
  "その他の項目": "Other items",
  "原産国名": "Country of origin",
  "原料原産地": "Origin of Raw Materials",
  "使用上の注意": "Precautions for use",
  "例）開缶後は、ガラスなどの容器に移し替えること": "(e.g.) After opening the can, transfer it to a glass or other container.",
  "調理方法": "Cooking Method",
  "例）加熱調理すること": "e.g. Cook with heat ",
  "使用方法": "Instructions for use",
  "殺菌方法": "Sanitization Method",
  "凍結前加熱の有無": "With or without pre-freezing heating",
  "例）加熱してありません": "e.g. Not pre-cooked",
  "加熱調理の必要性": "Necessity of cooking with heat",
  "例）加熱してください": "e.g. Please cook thoroughly",
  "でん粉含有率": "Starch Content",
  "例）3.5": "e.g. 3.5",
  "eg35": "e.g. 3.5", // 例）3.5だとなぜかバグる
  "無脂乳固形分": "Non-fat milk solids content",
  "例）8.3": "e.g. 8.3",
  "乳脂肪分": "Milk fat content",
  "その他項目": "Other items",
  "一括表示外の記載事項": "Description outside of labeling",
  "例）開封後は、お早めにお召し上がりください。": "e.g. After opening the package, please consume it as soon as possible.",
  "販売者・製造者・加工者・輸入者": "Seller/Manufacturer/Processor/Importer",
  "表示パターンを選択": "Select a display pattern",
  "製造者の所在地": "Manufacturer's Address",
  "例）都道府県市町村番地建物": "e.g. State, city, town, street, building",
  "例）都道府県市区町村番地建物": "e.g. State, city, town, street, building",
  "企業タブの情報を引用": "Copy the information from the company tab",
  "製造所名": "Manufactory name",
  "製造所の所在地": "Location of manufactory",
  "製造者固有記号": "Manufacturer's identification symbol",
  "製造者固有番号": "Manufacturer's identification number",
  "製造者 + 製造所": "Manufacturer + Manufactory",
  "製造者 + 製造所固有記号": "Manufacturer + Manufactory's identification symbol",
  "販売者 + 製造所": "Seller + Manufactory",
  "販売者 + 加工所": "Seller + Processor",
  "販売者 + 輸入者": "Seller + Importer",
  "販売者 + 製造所固有記号": "Seller + Manufactory's identification symbol",
  "販売者名": "Seller Name",
  "販売者の所在地": "Seller's Address",
  "加工所名": "Processing Plant Name",
  "加工所の所在地": "Location of processing plant",
  "輸入者名": "Importer Name",
  "輸入者の所在地": "Importer's Address",
  "加工者名": "Processors name",
  "加工者の所在地": "Location of processor",
  "加工者 + 加工所": "Processor + Processing plant",
  "一括表示全体に対する備考": "Notes for the overall label",
  "栄養成分（100g当たり）": "Nutrition (per 100g)",
  "成分（100g当たり）": "Nutrition (per 100g)",
  "入力内容": "Input Type",
  "栄養成分の備考": "Notes on Nutrition",
  "文科省公表の値を引用": "Cite from values published by the Japanese gov.",
  "食品名を入力して選択": "Enter the name of the product and select it.",
  "食品名を入力して検索": "Filter by product name",
  "ビタミン 13 種": "13 Vitamins",
  "例）1000": "e.g. 1000",
  "ミネラル 13 種": "13 Minerals",
  "ミネラル 12 種": "12 Minerals",
  "熱量": "Calorie",
  "たんぱく質": "Proteins",
  "脂質": "Fat",
  "炭水化物": "Carbohydrates",
  "食塩相当量": "Sodium",
  "水分": "Water Content",
  "灰分": "Ash Content",
  "飽和脂肪酸": "Saturated Fatty Acid",
  "一価不飽和脂肪酸": "Monounsaturated Fatty Acid",
  "多価不飽和脂肪酸": "Polyunsaturated Fatty Acid",
  "n-3 系脂肪酸": "n-3 fatty acid",
  "n-6 系脂肪酸": "n-6 fatty acid",
  "n-3系脂肪酸": "n-3 fatty acid",
  "n-6系脂肪酸": "n-6 fatty acid",
  "トランス脂肪酸": "Trans fatty acid",
  "コレステロール": "Cholesterol",
  "糖質": "Carbohydrates",
  "糖類": "Suger",
  "食物繊維": "Fiber",
  "水溶性食物繊維": "Soluble dietary fiber",
  "不溶性食物繊維": "Insoluble dietary fiber",
  "ビタミンA": "Vitamin A",
  "レチノール": "Retinol",
  "αカロテン": "α-carotene",
  "βカロテン": "β-carotene",
  "クリプトキサンチン": "Cryptoxanthin",
  "β-カロテン当量": "Beta-carotene equivalent",
  "ビタミンB1": "Vitamin B1",
  "ビタミンB2": "Vitamin B2",
  "ビタミンB6": "Vitamin B6",
  "ビタミンB12": "Vitamin B12",
  "ナイアシン": "Niacin",
  "葉酸": "Folic Acid",
  "パントテン酸": "Pantothenic Acid",
  "ビオチン": "Biotin",
  "ビタミンC": "Vitamin C",
  "ビタミンD": "Vitamin D",
  "ビタミンK": "Vitamin K",
  "ビタミンE": "Vitamin E",
  "αトコフェロール": "α-tocopherol",
  "βトコフェロール": "β-tocopherol",
  "γトコフェロール": "γ-tocopherol",
  "δトコフェロール": "δ-tocopherol",
  "亜鉛": "Zinc",
  "カリウム": "Potassium",
  "カルシウム": "Calcium",
  "クロム": "Chromium",
  "セレン": "Selenium",
  "鉄": "Iron",
  "銅": "Copper",
  "マグネシウム": "Magnesium",
  "マンガン": "Manganese",
  "モリブデン": "Molybdenum",
  "ヨウ素": "Iodine",
  "リン": "Phosphorus",
  "ナトリウム": "Sodium",
  "食品表示記載時の設定": "Setting for Food Label",
  "表示単位": "Display Unit",
  "例）1袋": "e.g. 1 bag",
  "表示単位数量": "Display Unit Quantity",
  "100": "100",
  "当たり": "Per",
  "ml当たりの重量": "Weight per ml",
  "画像": "Image",
  "画像を選択（複数選択可）": "Select an image (multiple selections allowed)",
  "ここに画像をドラッグ or クリック": "Drag image or click here",
  "包装材質": "Packaging Material",
  "包材の部位名": "Part Name",
  "包材の材質記号・規格": "Material Symbols and Standards",
  "例）PE": "e.g. PE",
  "重量（g）": "Weight (g)",
  "重量(g)": "Weight (g)",
  "数量": "Quantity",
  "例）3": "e.g. 3",
  "封緘方法": "Sealing Method",
  "例）ヒートシール": "e.g. Heat seal",
  "包材に表示する識別・認証マーク": "Identification and certification marks (displayed on package)",
  "識別・認証マークの種類": "Types",
  "識別・認証マーク": "Marks",
  "認定機関": "Certification Organization",
  "その他の表示項目": "Other Display Items",
  "遺伝子組替え表示（一括表示外）": "Display of GMO (outside of label)",
  "遺伝子組替え表示": "Display of GMO",
  "例）大豆（遺伝子組み換えでない）": "e.g. Soybeans (non-genetically modified)",
  "原料原産地の表示（一括表示外）": "Display of the ingredient production area (outside of label)",
  "原料原産地の表示": "Display of the ingredient production area",
  "例）国産": "e.g. Domestic",
  "アレルギー物質の混入（コンタミネーション）注意表示": "Allergen contamination warning",
  "アレルギー物質の混入（コンタミネーション）注意喚起": "Allergen contamination warning",
  "アレルギー物質の混入（コンタミネーションの注意表示）": "Allergen contamination warning",
  "例）本品製造工場では、卵・乳成分を含む製品を製造しております。": "(e.g.) Products containing eggs and dairy ingredients are manufactured at the plant where this product is produced.",
  "米（こめ）トレーサビリティ法対象区分・産地情報伝達方法": "Rice Traceability Law",
  "米トレーサビリティ法対象区分・産地情報伝達方法": "Rice Traceability Law",
  "対象区分": "Applicable or not",
  "対象": "Applicable",
  "対象外": "Not Applicable",
  "産地名の表示場所": "Display Location of Place of Origin",
  "包材全体に対する備考": "Remarks on the entire packaging material",
  "製造の基本情報": "Basic Manufacturing Info",
  "製造工程": "Manufacturing Process",
  "製造工程の添付ファイル": "Attachment File of Manufacturing Process",
  "画像・PDFを添付": "Attachment of Image or PDF",
  "テキスト": "Text",
  "例）原材肉の選別 → 原料肉の整理 → 味付け → 熟成 → 乾燥 → 冷却 → スライス → 計量 → 包装 → 金属探知機 → 製品検査 → 出荷": "e.g. Sorting of ingredients → Organizing of Ingredients → Seasoning → Maturing → Drying → Cooling → Slicing → Weighing → Packaging → Metal Detection → Product Inspection → Shipping",
  "工程名": "Process Name",
  "例）原料受け入れ": "e.g. Acceptance of Raw materials",
  "管理項目（対象など）": "Control Target",
  "管理項目": "Control Target",
  "管理ポイント": "Management Point",
  "例）自由入力": "e.g. Free input",
  "管理基準（CLなど）": "Management Standards (CL, etc.)",
  "モニタリング方法": "Monitoring Method",
  "危害要因": "Cause of Harm",
  "製造法基準": "Related Law",
  "誰が": "Who",
  "だれが": "Who",
  "どのように": "How",
  "使用器具・設備": "Used Equipment",
  "頻度": "Frequency",
  "基準外措置内容": "Measure on Out-of-standard",
  "基準判断者": "Standard Judge",
  "基準外判断者": "Person to Judge Non-standard",
  "危害": "Harm",
  "検証方法": "Verification Method",
  "何を": "What",
  "改善措置": "Improvement Measures",
  "方法": "Method",
  "記録文章名": "Recorded Text name",
  "記録文書名": "Recorded Text name",
  "異物検出方法": "Foreign Material Detection Method",
  "X線の対象材質": "Target material for X-rays",
  "球(φmm)": "Ball (φmm)",
  "例）2.5": "e.g. 2.5",
  "線": "Line",
  "直径(φmm)": "Diameter (φmm)",
  "長さ(mm)": "Length (mm)",
  "線 直径(φmm)": "Line Diameter (φmm)",
  "線 長さ(mm)": "Line Length (mm)",
  "金属探知機の使用": "Use of a Metal Detector",
  "金属探知機の使用有無": "Use of a Metal Detector",
  "金属探知機": "Metal Detector",
  "Fe(φmm)": "Fe (mm)",
  "例）1.0": "e.g. 1.0",
  "Sus(φmm)": "Sus (mm)",
  "例）3.0": "e.g. 3.0",
  "Non-Fe(φmm)": "Non-Fe(mm dia.)",
  "例）2.0": "e.g. 2.0",
  "金属探知機の動作確認頻度等の備考": "Notes about metal detectors (freq, etc.)",
  "ウェイトチェッカーの使用": "Use of Weight Checker ",
  "ウェイトチェッカーの使用有無": "Use of Weight Checker ",
  "ウェイトチェッカー": "Weight Checker ",
  "下限": "Lower limit (g)",
  "上限": "Upper limit (g)",
  "その他の異物検出方法": "Other foreign substance detection methods",
  "包装前後の最終殺菌方法": "Final Sterilization Method before and after Packaging",
  "最終殺菌方法": "Final Sterilization Method",
  "最終殺菌後の冷却方法": "Cooling Method after Final Sterilization",
  "衛生監視票": "Sanitation Monitoring Sheet",
  "製造品質添付資料": "Document of Manufacturing Quality",
  "製造品質添付資料1": "Document of Manufacturing Quality 1",
  "製造品質添付資料2": "Document of Manufacturing Quality 2",
  "衛生監視表など製造品質関連の添付資料（複数選択可）": "Documents about manufacturing (multiple selection)",
  "コンタミネーション": "Contamination",
  "同一工場内に存在するアレルギー物質": "Allergens used in the same factory",
  "製造ラインに混入する恐れのあるアレルギー物質": "Allergens that may potentially enter the manufacturing line",
  "コンタミネーション防止策": "Contamination Prevention Measures",
  "規格・検査": "Specifications and Inspections",
  "物理規格": "Physical Specifications",
  "項目": "Item",
  "測定方法": "Measurement Method",
  "検査頻度": "Inspection Frequency",
  "管理基準": "Control Criteria",
  "計測値": "Measurement Value",
  "検査機関": "Inspection Organization",
  "化学規格": "Chemical Specifications",
  "微生物規格": "Microbiological Specifications",
  "品質保持方法": "Quality Maintain Method",
  "品質保持剤の種類": "Type of Quality Retainer",
  "品質保持剤の名称": "Name of Quality Retention Materials",
  "製造全体に対する備考": "Remarks about manufacturing",
  "商品名・回収・提出先名などで検索": "Search by product name, collection or submission name, etc.",
  "対応すべき提出依頼": "Submission Requests to Respond",
  "事業者名": "Company Name",
  "共有数": "Share Count",
  "確認待": "Waiting for Confirmation",
  "要提出": "Submission Required",
  "中断": "Canceled",
  "相手方取下げ": "Client declined",
  "相手方中断": "Client canceled",
  "取下げ": "Declined",
  "提出済": "Submitted",
  "要修正": "Need correct",
  "先方確認中": "Client Checking",
  "未受領": "Not received",
  "差戻し": "Modifying",
  "要確認": "Need Confirm",
  "回収済": "Accepted",
  "通知あり": "Notification",
  "提出一覧": "Submissions",
  "商品名・回収・回収先名などで検索": "Search by product name, collection or collection destination name, etc.",
  "この依頼を取下げる": "I decline this request.",
  "未完了の項目の左にあるチェックボックスを1つ選択してください": "Please select one of the checkboxes to the left of the uncompleted item",
  "提出完了": "Complete",
  "自社商品名": "Product name",
  "依頼商品名": "Requested Product Name",
  "依頼元": "Requester",
  "依頼文・コメント履歴": "Comments",
  "依頼日": "Requested Date",
  "提出期限": "Deadline",
  "初回共有": "Initial Share",
  "最終更新": "Last-Update",
  "履歴を表示": "Show history",
  "残り": "remaining",
  "下記の内容で、規格書の提出依頼が来ています。": "We have received a request for submission of specs with the following information.",
  "依頼商品": "Requested Products",
  "締め切り": "deadline",
  "コメント": "Comment",
  "新しく作成": "Create New",
  "閲覧メンバーはこの機能は使用できません": "Viewing members cannot use this function",
  "配信済み規格書から選択": "Select from published specs",
  "配信済の自社規格書を選択して提出": "Select and submit your owned specs that have already been published",
  "下記を入力して提出してください。": "Please enter and submit the following",
  "配信済み自社規格書の選択": "Select published owned specs",
  "商品名、自社管理コードで絞り込み": "Narrow down by product name and internal code",
  "非公開にする項目": "Items to be hidden",
  "この依頼者への提供税抜単価(円)": "Unit price excluding tax provided to this client (yen)",
  "以上で提出": "Submit",
  "設定を変更したい場合、画面右上「アカウント設定」 > 「回収・提出先」から該当の企業を選択 > 「この回収・提出先に対する規格書の非公開項目」で設定してください。": "If you wish to change the settings, please go to 'Account Settings' in the upper right corner of the screen > select the appropriate company from the 'Collection/Submission Destination' > set the 'Non-public items in the standard form for this collection/submission destination'.",
  "規格書の修正依頼": "Request for amendment of specifications",
  "下記の内容で、規格書の修正依頼が来ています。": "We have received a request to revise the specifications with the following information.",
  "再提出する規格書を選択": "Select a spec to resubmit",
  "選択した規格書で再提出": "Resubmission with selected specifications",
  "選択した規格書を修正して再提出": "Revise and resubmit selected specifications",
  "部署": "Department | Departments",
  "手入力で追加" : "Add manually",
  "手入力で編集" : "Edit manually",
  "すべての回収規格書の一覧": "List of all collection specs",
  "この回収依頼を中断する": "Cancel this collection request",
  Tag: "Tag | Tags",
  "規格書の編集": "Edit Spec",
  "有り": "Yes",
  "無し": "No",
  "生鮮食品": "Fresh",
  "加工食品": "Processed",
  "添加物": "Additive",
  "添加物製剤": "Additive Manufacture Agent",
  "添加物（物質）": "Additive (Substance)",
  "添加物（製剤）": "Additive (Manufacture Agent)",
  "製剤": "Manufacture Agent",
  "製剤種別": "Manufacture Agent Type",
  "簡易入力": "Simple Input",
  "基本入力": "Basic Input",
  "詳細入力": "Detail Input",
  "そうけんくん": "Soukenkun",
  "メルクリウス": "Mercurius",
  "EBase": "EBase",
  "そうけんくん標準規格書（v2.3）取り込み": "Import Soukenkun Format(v2.3)",
  "MerQurius原料品質規格書（v3.1）取り込み": "Import MerQurius Format(v3.1)",
  "eBASE標準出力データ（フォルダ選択）取り込み": "Import eBASE Format(Folder Selection)",
  "標準フォーマット（PDF） 出力": "Export Standard Format(PDF)",
  "そうけんくん標準規格書（v2.3）出力": "Export Soukenkun Format(v2.3)",
  "MerQurius原料品質規格書（v3.1）出力": "Export MerQurius Format(v3.1)",
  "eBASE標準出力データ（ZIP）出力": "Export eBASE Format(ZIP)",
  "eBASEフォーマット（zip）": "eBASE Format(zip)",
  "そうけんくん（v2.3）フォーマット（Excel）": "Soukenkun Format(v2.3)(Excel)",
  "MerQurius原料品質規格書（v3.1）フォーマット（Excel）": "MerQurius Format(v3.1)(Excel)",
  "インフォマート規格書データ取り込み（ZIP）": "Informart Format（ZIP）",
  "BtoBプラットフォーム規格書（プレミアム全項目）の取り込み": "Import BtoB Platform Format(Premium All Items)",
  "株式会社インフォマートが提供する「BtoBプラットフォーム規格書」の出力形式「規格書（プレミアム全項目）」を取り込み、規格書を作成することができます。":
    "You can create a specification by importing the output format 'specification (premium all items)' provided by the 'BtoB Platform Specification' provided by Infomart",
  "印刷のための条件を設定してください。印刷準備には数十秒かかることがございます。なお、印刷して提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。":
    "Please set the conditions for printing. It may take up to a few seconds to prepare for printing.",
  "正確なデータ変換のための確認事項": "Confirmations for accurate data conversion",
  "この規格書データを「x」が読み取るために、当システム側で自動変換する内容、もしくは不足している内容があります。確認事項は、修正画面でも確認することができます。" :
    "In order for '{0}' to import the data correctly, some of the contents are missing or will be automatically converted. You can check the confirmation items on the correction screen.",
  "別の規格書と比較": "Compare with another spec",
  "印刷": "Print",
  "【自社】": "【Owned】",
  "【回収】": "【Collection】",
  "依頼名": "Request Name",
  "内容を確認し、承認": "Approve",
  "受領": "Accept",
  "承認しました": "Approved",
  "承認を取り消しました": "Approval cancelled",
  "承認を取り消す": "Cancel approval",
  "すべての承認が完了しています": "All approvals completed",
  "仕入れ価格": "Purchase Price",
  "円": "Yen",
  "円（税抜）": "Yen (excl. tax)",
  "酒類アルコール分": "Alcohol Ratio",
  "例": "e.g.",
  "例）": "e.g.",
  "製造所固有記号": "Manufactory's unique code",
  "参考情報": "Reference",
  "参考資料": "Reference Documents",
  "包材画像": "Package Images",
  "包材材質": "Package Material",
  "提出情報": "Submission Information",
  "提出元組織": "Submission Organization",
  "組織": "Organization",
  "手動追加した規格書の管理情報（自分の組織以外には表示されません）": "Manually added spec management info (not displayed for other organizations)",
  "自社の担当部署": "Department of my company",
  "実際に回収した規格書（紙のスキャンデータなど）": "Actual collection specs (scan data, etc.)",
  "この更新分について": "About this update",
  "この規格書全体について": "About all history of the spec",
  "作成": "Create",
  "この規格書の管理用のタグを設定すると、検索や整理などが効率よくできます。": "Setting tags for this spec will make searching and organizing easier.",
  "この規格書について、管理上のコメントがある場合、入力してください。": "If there is any comment regarding this spec, please input it here.",
  "更新分": "Version",
  "配信分": "Version",
  "ここにキーワードを入力して検索": "Search by keywords",
  "この処理は数十秒かかる場合があります": "This may take several tens of seconds",
  "内容をチェックしています": "Checking contents...",
  "出力しています": "Exporting...",
  "該当する": "Applicable",
  "該当しない": "Not applicable",
  "内容量(ml)を併記": "Show ml",
  "表示": "Visible",
  "非表示": "Invisible",
  "事業所名": "Company Name",
  "事業所": "Company",
  "全角カタカナ": "Katakana",
  "加入": "Joined",
  "非加入": "Not joined",
  "包材に産地名を表示": "Show name of the place of origin on the package",
  "全体に反映": "Apply to all",
  "未設定": "Not set",
  "としてログイン中": "is logging in",
  "タブ": "Tab",
  "全データを閲覧可能、編集可能": "View and Edit all data.",
  "全データを閲覧可能、所属外データの編集不可": "View all data and Edit belonged department's data",
  "所属外データを非表示、編集不可、引用可能": "View and Edit only belonged department's data, and Quote other department's data.",
  "所属外データを閲覧不可、編集不可、引用不可": "View and Edit only belonged department's data.",
  "管理メンバー": "Administrator",
  "編集メンバー": "Editable",
  "閲覧メンバー": "Viewable",
  "%以上": "% or more",
  "%未満": "% or less",
  "例）開缶後はガラス等の容器に移し換えること": "e.g. After opening the can, transfer it to a glass or other container",
  "確認事項": "Confirmation",
  "修正": "Modify",
  "内容": "Content",
  "無視して出力": "Ignore and export",
  "規格書を修正": "Modify spec",
  "は自動的に付与されます": "is automatically displayed",
  "出荷前": "Before shipment",
  "流通時": "In circulation",
  "入荷時": "On arrival",
  "例）0123456789012": "e.g. 0123456789012",
  "例）01234567890123": "e.g. 01234567890123",
  "の保存温度帯": " storage temp.",
  "任意の文字を入力することで、項目を追加することも可能です": "You can also add items",
  "製造工程が分かるファイルを添付してください。": "请附上能了解制造过程的文件。",
  "例）ISO 22000 取得済": "e.g. ISO 22000 obtained",
  "この規格書と連携している食品表示の商品（または内訳の原材料）が更新されました。": `The product in "Smart Food Label" connected to this spec has been updated`,
  "詳細画面の「管理情報」から承認条件を満たしてから「配信」してください。": `Please approve this version in the "Internal" page to publish.`,
  "担当部署": "Departments",
  "回収規格書": "Collection spec",
  "比較元": "Compare from",
  "比較先": "Compare to",
  "提出先の組織名": "Organization name to submit to",
  "はい": "Yes",
  "いいえ": "No",
  "配信済": "Published",
  "この規格書は提出済みです": "This spec has been submitted",
  "この規格書は受領済みです": "This spec has been accepted",
  "商品単位あたりの重量を入力してください": "Please input weight per unit",
  "風袋込の商品単位あたり重量を入力してください": "Please input weight per unit including the bag",
  "添加物名を入力して検索": "Search by adding name",
  "規格書を提出する": "Submit spec",
  "８文字以上の英数字": "8 characters or more",
  "英数字記号": "alphanumeric and/or symbol",
  "この設定は現在変更できません": "This setting cannot be modified",
  "この画面では、回収規格書の作成時に選択する提出元のデータを編集・削除することができます。": "You can edit and delete data from the submission clients in this page",
  "この画面では、規格書の作成時などに入力を補助する企業のデータを編集・削除することができます。※ここから編集・削除しても過去に配信した規格書の情報は書き換えられません。":
    "You can edit and delete data of the companies in this page. ※You can't modify or delete data after publishing spec.",
  "このデータを削除": "Delete this data",
  "メンバーの追加": "Add member",
  "メンバーを編集": "Edit member",
  "所属部署": "Department",
  "管理メンバーはこの項目は必須です": "This is required to administrator member",
  "メンバーを更新しました": "Member has been updated",
  "保存しました": "Saved",
  "関連事業者の削除": "Delete related companies",
  "承諾して削除": "Delete",
  "削除しました": "Deleted",
  "このデータを削除します。<br><br>このデータを削除しても、過去にこの関連事業者を使って配信された規格書の内容は、変更されません。":
    "This data will be deleted. <br><br>You can't modify or delete data after publishing spec.",
  "回収・提出先（提出元企業）": "Clients",
  "この画面は、管理メンバーのみに表示されています。こちらでは、現在ご契約のプランの確認や、過去の利用詳細を確認することができます。":
    "This page is only visible to administrator members. You can check the current plan and past usage details here.",
  "次回請求": "Next billing",
  "期限切れ": "Expired",
  "金額": "Price",
  "カード番号": "Card number",
  "利用料金は各契約期間の初日に請求され、お客様のアカウントに反映されるまで数日かかることもございます。":
    "The fee will be charged on the first day of each contract period. It may take up to a few days to reflect to your account.",
  "食品表示プランの解約": "Cancel Food Label Plan",
  "規格書プランの解約": "Cancel Spec Plan",
  "食品表示プランの再契約": "Re-subscribe Food Label Plan",
  "規格書プランの再契約": "Re-subscribe Spec Plan",
  "再契約": "Re-subscribe",
  "プランを再開しました": "Plan has been re-subscribed",
  "プランの再開に失敗しました": "Failed to re-subscribe plan",
  "了承して解約": "Cancel Plan",
  "領収書番号": "Receipt number",
  "停止予定": "will be suspended",
  "停止日": "will be suspended on",
  "ログアウトしました": "Logged out",
  "円/月": "yen/month",
  "/月": "/month",
  "この規格書を配信します。よろしいですか？": "Do you want to publish this spec?",
  "配信しました": "Published",
  "未保存の変更があります。このまま進むと内容は保存されませんが、よろしいですか？": "There are unsaved changes. Do you want to proceed without saving?",
  "本当にキャンセルしますか？": "Do you really want to cancel?",
  "作成のキャンセル": "Cancel creation",
  "変更のキャンセル": "Cancel modification",
  "作成中の内容は保存されません。": "The content will not be saved.",
  "前回から変更した内容は保存されません。": "The content will not be saved.",
  "トークン作成に失敗しました": "Failed to create token",
  "クレジットカードの登録に失敗しました": "Failed to register credit card",
  "カード情報を更新しました": "Card information has been updated",
  "画面をリロードします": "Reloading the page",
  "プランの解約に失敗しました": "Failed to cancel plan",
  "プランを解約しました": "Plan has been cancelled",
  "までは利用可能です": "You can use it until {0}",
  "無料で 14 日間、全機能をお使いいただくことができます。": "You can use all functions for free for 14 days.",
  "以上で送信": "Send",
  "お問い合わせ内容": "Inquiry content",
  "資料のご請求・大量の原材料や商品を扱い企業様の相談・その他ご質問がございましたら、以下よりお気軽にお問い合わせください":
    "If you have any questions about ingredients or products, please feel free to contact us.",
  "こちらにお問い合わせ内容を記入ください。": "Please input your inquiry content here.",
  "メンバー削除": "Delete member",
  "メンバーを削除しました": "Member has been deleted",
  "をメンバーから削除しますか？": "Do you want to delete {0} from members?",
  "の招待を取り消しますか？": "Do you want to cancel the invitation to {0}?",
  "招待取り消し": "Cancel invitation",
  "招待を取り消しました": "Invitation has been cancelled",
  "一括削除": "Delete items",
  "選択されたn項目を一括で削除しますか？(この操作はもとに戻せません)": "Do you want to delete the selected {0} items? (This operation cannot be undone)",
  "事業者がみつかりませんでした": "Company was not found",
  "有効な事業者が選択されていません": "No valid company is selected",
  "規格書の提出依頼を送信しました": "Spec submission request has been sent",
  "この回収依頼を中断しますか？相手方にも、提出の必要性がないことを明示します。<br><br>同じ内容で回収したい場合には、再度回収依頼を出してください。":
    "Do you want to cancel this collection request? This will also be notified to the other company.<br><br>If you want to collect the same content again, please send a collection request again.",
  "回収依頼を中断しました": "Collection request has been cancelled",
  "確認が必要な項目があります": "There are items that require confirmation",
  "回収規格書一覧.csv": "collection_spec_list.csv",
  "xのデータ取り込みが完了しました": "Import of {0} Data has been completed",
  "不足": "Missing",
  "この提出依頼を取下げますか":
    "Do you want to decline this submission request? The sender company will be informed that the submission has been declined.<br><br>To submit this request again, they will need to send the request again to us",
  "この提出依頼を取り下げる": "Decline this submission request",
  "取り下げ理由（相手方に送信）": "Reason for declining (sent to the sender company)",
  "以上で取下げ": "Decline",
  "了承して取下げ": "Decline",
  "【注意1】再び提出するためには、相手方から再度「提出依頼」を送付してもらう必要があります。":
    "【Note 1】To submit this request again, the sender company will need to send the request again.",
  "【注意2】誤って規格書を提出した場合は、こちらの処理は行わず、{0}の対応方法をお読みください。":
    "【Note 2】If you have submitted the spec by mistake, please read {0} for the appropriate action.",
  "依頼を取り下げました": "Submission request has been declined",
  "受領しました": "Accepted",
  "更新しました": "Updated",
  "承認が必要です": "Approval is required",
  "回収規格書（x）を手動作成しました。": "Created a collection spec （{0}） manually.",
  "回収規格書（x）を手動更新しました。": "Updated a collection spec （{0}） manually.",
  "xを下書き保存しました。": "Saved {0} as draft.",
  "xをとして保存しました。": "Saved {0}",
  "提出可能なステータスではありません": "It is not a status that can be submitted",
  "規格書を提出しました": "Spec has been submitted",
  "xを提出しました": "{0} has been submitted",
  "ユーザーが作成されました": "User has been created",
  "ログインが必要です": "Login is required",
  "ログインしました": "Logged in",
  "メールアドレスの変更が完了しました": "Email address has been changed",
  "メールアドレスの変更に失敗しました。お手数をおかけ致しますが、最初からやり直してください": "Failed to change email address. Please try again from the beginning.",
  "確認メールを送信しました。メール内のリンクをクリック後に変更が適用されます": "Confirmation email has been sent. The change will be applied after clicking the link in the email.",
  "パスワードを更新しました": "Password has been updated",
  "プロフィールを更新しました": "Profile has been updated",
  "プロフィールを更新しました。画面更新のためリロードします": "Profile has been updated. Reloading the page.",
  "規格書設定を更新しました。画面をリロードします": "Spec settings have been updated. Reloading the page.",
  "ここでは、皆さまから寄せられるご質問の中から特に多かったものを共有しています。": "Here we share the most frequently asked questions.",
  "この画面では、回収・提出先との状態や自社用のメモを記述することができます。": "You can describe the status with the collection and submission company and your own memo on this screen.",
  "管理情報（自分の組織以外には表示されません）": "Management information (only displayed to your company)",
  "先方担当者名": "Contact person name at the other company",
  "回収・提出先の主要区分": "Category",
  "創業年": "Est. Year",
  "年商（推定含む）": "Annual Sales",
  "従業員数": "Number of Employees",
  "この回収・提出先に対する規格書の非公開項目": "Hidden items for this company",
  "過去に提出した企業にも配信されます": "This will also be sent to companies that have already submitted",
  "詳細画面の「管理情報」から承認条件を満たしてから「提出」してください。": "Please go to the 'Internal' tab on the detail screen and meet the approval conditions.",
  "アプリケーションが更新されました": "Application has been updated",
  "画面の再読込を行ってください<br>（ここをクリックして再読込する）": "Please reload the page<br>（Click here to reload）",
  "主な用途": "Main Usage",
  "その他の用途": "Other Usage",
  "該当する項目がありません。": "No matching items.",
  "特定遺伝子組換え作物": "specific GMO",
  "部署設定の有効化": "Enable department settings",
  "部署に属していないメンバーは、自動的に「管理」所属に設定されます": "Members who are not assigned to a department will be automatically assigned to the 'Mng.' department.",
  "確認して閉じる": "Confirm and close",
  "1Aaあア": "xxx",
  "通知": "Notification",
  "回収規格書の定期確認": "Periodical check of collected spec",
  "通知のタイミング": "Notification Timing",
  "未確認時の再通知": "Reminder for unconfirmed",
  "再通知しない": "Do not remind",
  "この設定を変更すると、新しい条件に一致する通知が画面上に作成されます（メールは送信されません）": "If you change this setting, notifications that match the new conditions will be created on the screen (not send email).",
  "「回収規格書の定期確認」の変更": "Change of 'Periodical check of collected spec'",
  "xにこの規格書の新しい版が配信されました。影響範囲を確認して、問題がなければ、通知を削除してください。":
    "A new version of the spec was published at {0}. Please check the changes and remove this notice if there is no problem.",
  "この規格書は、「最終更新日」より t日以上 が経過しています。規格書の最新状態を確認し、問題がなければ、この通知を削除してください。":
    "More than {0} days had passed since last updated date. Please check the latest status of the spec and if there are no problems, delete this notice.",
  "確定": "OK",
  "最新状態を確認したため、この通知を削除": "Confirmed. Delete this notice",
  "通知を削除": "Delete this notice",
  "表示 / 非表示 の切替え": "Toggle Visibility",
  "表示リスト": "Visible List",
  "非表示リスト": "Hidden List",
  'この画面は、組織内の「管理者」のみが操作可能です。以下の設定はあなただけではなく、組織全体に影響されるため、変更の際はくれぐれも注意してください。':
    "This page is only available to 'Admin' members. Please be careful when changing the following settings as they will affect the entire organization.",
  "回収依頼": "Request for collection",
  "入力必須事項の初期項目": "Default items for required fields",
  "ホームへ": "Back to home",
  "承認条件を満たしてから「受領」してください。": "Please meet the approval conditions and then 'Accept'.",
  "コチラ": "Here",
  "登録後に変更することが可能です。": "You can change this after registration.",
  "重量単位": "Weight Unit",
  "同一工場内": "in the same factory",
  "同一製造ライン": "Same Manufacturing Line",
  "X線検査機の使用有無": "Use of X-ray Inspection Machine",
  "別サービス「スマート食品表示」を使えば、「規格書（原材料）」を選択して割合を入力するだけで、自動的に原材料名・栄養成分を計算・作成することができます。詳しくは右の動画をご覧ください。":
    "With using 'Smart Food Label' service You can automatically calculate and create ingredient names and nutritional information by selecting 'Spec (Ingredients)' and entering the ratio. Please see the video on the right for more details.",
  "スマート食品表示を開く": "Open Smart Food Label",
  '最大横幅で表示': "Full width",
  "配合率を「全体配合率」で入力": "Input the ratio as 'in Total'",
  '確認': 'Confirmation'
}
